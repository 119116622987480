<template>
  <v-layout column>
    <div class="align-center text-center" style="margin-top: 20px">
      <v-card elevation="6" max-width="800" style="margin: auto">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>Device Setup</v-toolbar-title>
        </v-toolbar>

        <!--class that defines paddings(left, right, top && bottom-->
        <div class="pl-4 pr-4 pt-2 pb-4">
          <p></p>

          Please connect to the sensor WIFI and then click the following
          <a href="http://192.168.4.1/" target="_blank">link</a> to access the
          device setup page.

          <p>
            Read the device instructions for a detailed guide on how to
            configure your device.
          </p>

          <p>
            Once the setup is complete, click the finish button to complete the
            registration and be redirected to the lobby page.
          </p>

          <br />

          <v-btn elevation="2" @click="finish">
            <v-icon class="mr-1">check</v-icon>
            Finish
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-layout>
</template>

<script>
export default {
  methods: {
    finish() {
      this.$router.push({
        name: "login",
        query: { redirect: "/login" },
      });
    },
  },
  mounted() {
    if (this.$store.state.isUserLoggedIn) {
      this.$router.push({
        name: "lobby",
        query: { redirect: "/lobby" },
      });
    }
  },
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
